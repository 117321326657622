// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cast-page {
    width: 100vw;
    height: 100vh;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.noHover {
    width: 100%;
    height: auto;
}

.btnFuckkkkk:not(.noHover):hover {
    width: 100%;
    height: auto;
}

.linkClass {
    width: 100%;
    height: auto;
}

.centeredSvg {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.box {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 20px);
    min-width: 200px;
    margin: 10px; 
    padding: 20px;
}

.box img {
    max-width: 100%;
}

@media (max-width: 768px) and (max-height: 1024px) {
    .cast {
        letter-spacing: -0.16vw;
    }
    .cast-header h1 {
        letter-spacing: -0.16vw;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Cast.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,YAA6B;IAA7B,cAA6B;IAA7B,+BAA6B;IAC7B,gBAAgB;IAChB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".cast-page {\r\n    width: 100vw;\r\n    height: 100vh;\r\n}\r\n\r\n.container {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n}\r\n\r\n.noHover {\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.btnFuckkkkk:not(.noHover):hover {\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.linkClass {\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.centeredSvg {\r\n    align-self: center;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n}\r\n\r\n.box {\r\n    flex: 0 0 calc(33.33% - 20px);\r\n    min-width: 200px;\r\n    margin: 10px; \r\n    padding: 20px;\r\n}\r\n\r\n.box img {\r\n    max-width: 100%;\r\n}\r\n\r\n@media (max-width: 768px) and (max-height: 1024px) {\r\n    .cast {\r\n        letter-spacing: -0.16vw;\r\n    }\r\n    .cast-header h1 {\r\n        letter-spacing: -0.16vw;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
