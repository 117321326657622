// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
    width: 75%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.philosophy-header h1 {
    font-size: 6.3vw;
    margin-top: 3%;
    letter-spacing: -0.16vw;
}

.noHover {
    width: 100%;
}

.btnFuckkkkk:not(.noHover):hover {
    width: 100%;
}

.linkClass {
    width: 100%;
    height: auto;
}

.centeredSvg {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.philosophy {
    letter-spacing: -0.16vw;
}

.philosophy h2 {
    letter-spacing: -0.16vw;
}

@media (max-width: 768px) and (max-height: 1024px) {
    .philosophy {
        letter-spacing: -0.16vw;
    }
    .philosophy-header h1 {
        letter-spacing: -0.16vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Philosophy.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".text {\r\n    width: 75%;\r\n    margin: auto;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n}\r\n\r\n.philosophy-header h1 {\r\n    font-size: 6.3vw;\r\n    margin-top: 3%;\r\n    letter-spacing: -0.16vw;\r\n}\r\n\r\n.noHover {\r\n    width: 100%;\r\n}\r\n\r\n.btnFuckkkkk:not(.noHover):hover {\r\n    width: 100%;\r\n}\r\n\r\n.linkClass {\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.centeredSvg {\r\n    align-self: center;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n}\r\n\r\n.philosophy {\r\n    letter-spacing: -0.16vw;\r\n}\r\n\r\n.philosophy h2 {\r\n    letter-spacing: -0.16vw;\r\n}\r\n\r\n@media (max-width: 768px) and (max-height: 1024px) {\r\n    .philosophy {\r\n        letter-spacing: -0.16vw;\r\n    }\r\n    .philosophy-header h1 {\r\n        letter-spacing: -0.16vw;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
