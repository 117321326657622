import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import '../styles/Contact.css'
import logo from '../assets/streetscasting_logo.svg';

const Contact = () => {
  return (
    <div className='contact-page'>
      <section className='contact-header'>
        <Link to='/' className='centeredSvg btnFuckkkkk noHover' >
          <img className='linkClass centeredSvg' src={logo} />
        </Link>
      </section>
      <section><h1>CONTACT</h1></section>
      <section className='sectionOne'>
        <section><h2>FOR BOOKINGS, CASTINGS, OR ANY ADDITIONAL INFO</h2></section>
        <section><h2>PLEASE REACH OUT AT:</h2></section>
      </section>
      <section className='sectionTwo'>
        <section><h2 className='numbersContact'>+995 599 013 353</h2></section>
        <section><h2>STREETSCASTING4@GMAIL.COM</h2></section>
        <section><h2>TBILISI, GEORGIA</h2></section>
      </section>
    </div>
  )
}

export default Contact;