import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Cast from './pages/Cast';
import Philosophy from './pages/Philosophy';
import Apply from './pages/Apply';
import Contact from './pages/Contact';

import Tina from '../src/pages/personal pages/Tina';
import Amina from '../src/pages/personal pages/Amina';
import Andria from '../src/pages/personal pages/Andria';
import Destiny from '../src/pages/personal pages/Destiny';
import Lekso from '../src/pages/personal pages/Lekso';
import Eleni from '../src/pages/personal pages/Eleni';
import Maria from '../src/pages/personal pages/Maria';
import Salome from '../src/pages/personal pages/Salome';
import Manish from '../src/pages/personal pages/Manish';
import Misa from '../src/pages/personal pages/Misa';
import Sani from '../src/pages/personal pages/Sani';
import Jojo from '../src/pages/personal pages/Jojo';
import Nika from '../src/pages/personal pages/Nika';
import Sato from '../src/pages/personal pages/Sato';
import Jorge from '../src/pages/personal pages/Jorge';
import Lika from '../src/pages/personal pages/Lika';
import Mariam from '../src/pages/personal pages/Mariam';
import Nina from '../src/pages/personal pages/Nina';
import Mariam1 from '../src/pages/personal pages/Mariam1';
import Mate from '../src/pages/personal pages/Mate';
import Anuka from '../src/pages/personal pages/Anuka';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='cast' element={<Cast />} />
            <Route path='Tina' element={<Tina />} />
            <Route path='Amina' element={<Amina />} />
            <Route path='Andria' element={<Andria />} />
            <Route path='Destiny' element={<Destiny />} />
            <Route path='Lekso' element={<Lekso />} />
            <Route path='Eleni' element={<Eleni />} />
            <Route path='Maria' element={<Maria />} />
            <Route path='Salome' element={<Salome />} />
            <Route path='Manish' element={<Manish />} />
            <Route path='Misa' element={<Misa />} />
            <Route path='Sani' element={<Sani />} />
            <Route path='Jojo' element={<Jojo />} />
            <Route path='Nika' element={<Nika />} />
            <Route path='Sato' element={<Sato />} />
            <Route path='Jorge' element={<Jorge />} />
            <Route path='Lika' element={<Lika />} />
            <Route path='Mariam' element={<Mariam />} />
            <Route path='Nina' element={<Nina />} />
            <Route path='Mariam1' element={<Mariam1 />} />
            <Route path='Mate' element={<Mate />} />
            <Route path='Anuka' element={<Anuka />} />
            <Route path='philosophy' element={<Philosophy />} />
            <Route path='apply' element={<Apply />} />
            <Route path='contact' element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
