import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import '../styles/Cast.css'
import AMINA from '../assets/CAST/AMINA.JPG'
import LEKSO from '../assets/CAST/LEKSO.JPG'
import MARIA from '../assets/CAST/MARIA.jpg'
import SALOME from '../assets/CAST/SALOME.jpg'
import TINA from '../assets/CAST/TINA.jpg'
import ELENI from '../assets/CAST/ELENI.jpg'
import MANISH from '../assets/CAST/MANISH.jpg'
import MISA from '../assets/CAST/MISA.jpg'
import ANDRIA from '../assets/CAST/ANDRIA.jpg'
import DESTINY from '../assets/CAST/DESTINY.jpg'
import SANI from '../assets/CAST/SANI.jpg'
import JOJO from '../assets/CAST/JOJO.jpg'
import NIKA from '../assets/CAST/NIKA.jpg'
import TEMO from '../assets/CAST/TEMO.jpg'
import SATO from '../assets/CAST/SATO.jpg'
import JORGE from '../assets/CAST/JORGE.jpg'
import LIKA from '../assets/CAST/LIKA.jpg'
import MARIAM from '../assets/CAST/MARIAM.jpg'
import NINA from '../assets/CAST/NINA.JPG'
import MARIAM1 from '../assets/CAST/MARIAM1.jpg'
import MATE from '../assets/CAST/MATE.jpg'
import ANUKA from '../assets/CAST/ANUKA.jpg'
import logo from '../assets/streetscasting_logo.svg';

import Tina from './personal pages/Tina';
import Amina from './personal pages/Amina';
import Andria from './personal pages/Andria';
import Anuka from './personal pages/Anuka';
import Destiny from './personal pages/Destiny';
import Eleni from './personal pages/Eleni';
import Jojo from './personal pages/Jojo';
import Jorge from './personal pages/Jorge';
import Lekso from './personal pages/Lekso';
import Lika from './personal pages/Lika';
import Manish from './personal pages/Manish';
import Maria from './personal pages/Maria';
import Mariam from './personal pages/Mariam';
import Mariam1 from './personal pages/Mariam1';
import Mate from './personal pages/Mate';
import Misa from './personal pages/Misa';
import Nika from './personal pages/Nika';
import Nina from './personal pages/Nina';
import Salome from './personal pages/Salome';
import Sani from './personal pages/Sani';
import Sato from './personal pages/Sato';
import Temo from './personal pages/Temo';

const Cast = () => {
  return (
    <div className='cast-page'>
      <section className='cast-header noHover'>
        <Link to='/' className='centeredSvg btnFuckkkkk noHover' >
          <img className='linkClass centeredSvg noHover' src={logo} />
        </Link>
      </section>
      <section><h2>cast</h2></section>
      <section><h2>photography by sikhshire</h2></section>
      <section className='container'>
        <div className='box'>
          <Link to="/Tina">
            <div>
              <img src={TINA} alt="TINA" />
              <h3>tina</h3>
            </div>
          </Link>
        </div>
        <div className='box'>
          <Link to="/Amina">
            <div>
              <img src={AMINA} alt="AMINA" />
              <h3>amina</h3>
            </div>
          </Link>
        </div>
        <div className='box'>
          <Link to="/Andria">
            <div>
              <img src={ANDRIA} alt="ANDRIA" />
              <h3>andria</h3>
            </div>
          </Link>
        </div>
        <div className='box'>
        <Link to="/Destiny">
        <div>
          <img src={DESTINY} alt="DESTINY" />
          <h3>destiny</h3>
        </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Lekso">
        <div>
          <img src={LEKSO} alt="LEKSO" />
          <h3>lekso</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Eleni">
        <div>
          <img src={ELENI} alt="ELENI" />
          <h3>eleni</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Maria">
        <div>
          <img src={MARIA} alt="MARIA" />
          <h3>maria</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Salome">
        <div>
          <img src={SALOME} alt="SALOME" />
          <h3>salome</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Manish">
        <div>
          <img src={MANISH} alt="MANISH" />
          <h3>manish</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Misa">
        <div>
          <img src={MISA} alt="MISA" />
          <h3>misa</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Sani">
        <div>
          <img src={SANI} alt="SANI" />
          <h3>sani</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Jojo">
        <div>
          <img src={JOJO} alt="JOJO" />
          <h3>jojo</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Nika">
        <div>
          <img src={NIKA} alt="NIKA" />
          <h3>nika</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Temo">
        <div>
          <img src={TEMO} alt="TEMO" />
          <h3>temo</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Sato">
        <div>
          <img src={SATO} alt="SATO" />
          <h3>sato</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Jorge">
        <div>
          <img src={JORGE} alt="JORGE" />
          <h3>jorge</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Lika">
        <div>
          <img src={LIKA} alt="LIKA" />
          <h3>lika</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Mariam">
        <div>
          <img src={MARIAM} alt="MARIAM" />
          <h3>mariam</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Nina">
        <div>
          <img src={NINA} alt="NINA" />
          <h3>nina</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Mariam">
        <div>
          <img src={MARIAM1} alt="MARIAM" />
          <h3>mariam</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Mate">
        <div>
          <img src={MATE} alt="MATE" />
          <h3>mate</h3>
          </div>
        </Link>
        </div>
        <div className='box'>
        <Link to="/Anuka">
        <div>
          <img src={ANUKA} alt="ANUKA" />
          <h3>anuka</h3>
          </div>
        </Link>
        </div>
      </section>
    </div>
  )
}

export default Cast;