import React, {useState} from 'react'
import { NavLink, Link } from 'react-router-dom';
import '../styles/Apply.css'
import { ContactUs } from '../components/Email';
import logo from './streetscasting_logo.svg';

const Apply = () => {
  return (
    <div className='apply-page'>
      <section className='apply-header'>
        <Link to='/' className='centeredSvg btnFuckkkkk noHover' >
          <img className='linkClass centeredSvg' src={logo} />
        </Link>
      </section>
      <section><h2>apply</h2></section>
      <section className="form">
        <ContactUs />
      </section>
    </div>
  )
}

export default Apply;
