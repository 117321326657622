// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/FRONTPAGE/tinamainpage.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apply-page {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
}

.apply-header h1 {
    font-size: 6.3vw;
    margin-top: 3%;
    letter-spacing: -0.16vw;
}

.noHover {
    width: 100%;
}

.btnFuckkkkk:not(.noHover):hover {
    width: 100%;
}

.linkClass {
    width: 100%;
    height: auto;
}

.centeredSvg {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.form h2 {
    letter-spacing: -0.16vw;
    font-family: 'Xenku', sans-serif; 
}

.form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Xenku', sans-serif; 
    text-align: -webkit-center;
    width: 100%;
}

@media (max-width: 767px) {
    .apply-page {
        letter-spacing: -0.16vw;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    
}`, "",{"version":3,"sources":["webpack://./src/styles/Apply.css"],"names":[],"mappings":"AAAA;IACI,yDAA+D;IAC/D,sBAAsB;IACtB,2BAA2B;IAC3B,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,gCAAgC;IAChC,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI;QACI,uBAAuB;IAC3B;AACJ;;AAEA;;AAEA","sourcesContent":[".apply-page {\r\n    background-image: url('/src/assets/FRONTPAGE/tinamainpage.png');\r\n    background-size: cover;\r\n    background-position: center;\r\n    width: 100vw;\r\n    height: 100vh;\r\n}\r\n\r\n.apply-header h1 {\r\n    font-size: 6.3vw;\r\n    margin-top: 3%;\r\n    letter-spacing: -0.16vw;\r\n}\r\n\r\n.noHover {\r\n    width: 100%;\r\n}\r\n\r\n.btnFuckkkkk:not(.noHover):hover {\r\n    width: 100%;\r\n}\r\n\r\n.linkClass {\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.centeredSvg {\r\n    align-self: center;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n}\r\n\r\n.form h2 {\r\n    letter-spacing: -0.16vw;\r\n    font-family: 'Xenku', sans-serif; \r\n}\r\n\r\n.form {\r\n    display: flex;\r\n    flex-wrap: nowrap;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-family: 'Xenku', sans-serif; \r\n    text-align: -webkit-center;\r\n    width: 100%;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .apply-page {\r\n        letter-spacing: -0.16vw;\r\n    }\r\n}\r\n\r\n@media (min-width: 768px) and (max-width: 1023px) {\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
