// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Xenku.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Xenku.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Xenku.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Xenku.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/Xenku.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Xenku';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('otf'),
       url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('ttf'),
       url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('eot'),       
       url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
       url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2');
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Xenku', sans-serif; 
  text-transform: uppercase;
  color: #ff4900;
  text-align: center;
  background-color: #141414;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

a:hover {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

p {
  letter-spacing: -0.16vw;
  font-size: xx-large;
  margin-top: 10%;
}

iframe {
    display: block; /* iframes are inline by default */
    background: #000;
    border: none; /* Reset default border */
    height: 100vh; /* Viewport-relative units */
    width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB;;;;8DAI+C;AACjD;;AAEA;EACE,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gCAAgC;EAChC,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;IACI,cAAc,EAAE,kCAAkC;IAClD,gBAAgB;IAChB,YAAY,EAAE,yBAAyB;IACvC,aAAa,EAAE,4BAA4B;IAC3C,YAAY;AAChB","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n@font-face {\n  font-family: 'Xenku';\n  src: url('./fonts/Xenku.otf') format('otf'),\n       url('./fonts/Xenku.ttf') format('ttf'),\n       url('./fonts/Xenku.eot') format('eot'),       \n       url('./fonts/Xenku.woff') format('woff'),\n       url('./fonts/Xenku.woff2') format('woff2');\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: 'Xenku', sans-serif; \n  text-transform: uppercase;\n  color: #ff4900;\n  text-align: center;\n  background-color: #141414;\n  overflow-x: hidden;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n  display: inline-block;\n}\n\na:hover {\n  text-decoration: none;\n  color: inherit;\n  display: inline-block;\n}\n\np {\n  letter-spacing: -0.16vw;\n  font-size: xx-large;\n  margin-top: 10%;\n}\n\niframe {\n    display: block; /* iframes are inline by default */\n    background: #000;\n    border: none; /* Reset default border */\n    height: 100vh; /* Viewport-relative units */\n    width: 100vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
